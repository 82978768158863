import { createContext, useState, useEffect, useContext } from "react";
import { apiPost, apiGet, apiGetNew } from "../Utilities/userAuth";
import { getStorage } from "../Utilities/storage";
import { handleError } from "../Utilities/Services/customHelper";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext({});
const NotificationsContext = createContext();

// Auth Provider
const AuthProvider = (props) => {
  const [token, setToken] = useState("");
  let value = localStorage.getItem("userInfo") ? localStorage.getItem("userInfo") : false;
  const [isAuthenticated, setIsAuthenticated] = useState(value);

  /* Verify if token is present or not */
  useEffect(() => {
    if (token) {
      // verifyToken(token);
    } else {
      if (localStorage.getItem("token")) {
        setToken(localStorage.getItem("token"));
        // verifyToken(localStorage.getItem("token"));
      } else {
        setIsAuthenticated(false);
      }
    }
  }, []);

  /* Used to set authentication */
  const activateAuthentication = (value) => {
    setIsAuthenticated(value);
    return;
  };
  const { REACT_APP_API_URL } = process.env;
  /* Function to verify if token is valid or not */
  const verifyToken = (token) => {
    let userInfo = getStorage("userInfo");
    let token22 = token ? token.split('"') : "";

    let url = `${REACT_APP_API_URL}/users/validateToken`;
    const params = {
      token: token22[1],
    };
    apiPost(url, params)
      .then((response) => {
        // console.log(response.data.code)
        if (parseInt(response.data.code) === 200) {
          setIsAuthenticated(true);
          //localStorage.setItem("userInfo", response.data.data);
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {});
  };

  /* Used to set token */
  const activateToken = (value) => {
    setToken(value);
    return;
  };

  const authContextValue = {
    activateToken,
    activateAuthentication,
    isAuthenticated,
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

// Notification Provide
export const NotificationsProvider = ({ children }) => {
  const { REACT_APP_API_URL } = process.env;
  let userInfo = getStorage("userInfo");
  const [showNotification, setShowNotification] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}/notification/getAll`;
      const params = {
        // token: userInfo.token,
        // user_id: userInfo.user_info.id,
        
      };
      apiGetNew(url, params, userInfo?.token)
        .then((response) => {
          if (response.data.code === 200) {
            setShowNotification(response.data.data);
          }
        })
        .catch((error) => {
          handleError();
        });
    }
  };
  return <NotificationsContext.Provider value={{ showNotification, getNotifications }}>{children}</NotificationsContext.Provider>;
};

const Auth = () => useContext(AuthContext);

export { AuthProvider, Auth };
export const useNotifications = () => {
  return useContext(NotificationsContext);
};
