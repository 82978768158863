import React, { Component } from "react";
import axios from "axios";
import { removeStorage } from "./storage";

export async function apiDelete(url, params) {
  return axios
    .delete(url, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export async function apiDeleteNew(url, params, token) {
  return axios
    .delete(url, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiGetOne(url, params) {
  return axios
    .get(url, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiGetOneNew(url, params, token, navigate) {
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        removeStorage("userInfo");
        removeStorage("token");
        navigate("/login");
      }
    });
}

export async function apiGet(url, params) {
  return axios
    .get(url, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiGetNew(url, params, token, navigate) {
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        removeStorage("userInfo");
        removeStorage("token");
        navigate("/login");
      }
      return error.response;
    });
}
export async function apiPostNew(url, data, token) {
  return axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export async function apiPost(url, params, headers) {
  let authorizedHeaders = "";
  if (headers) {
    authorizedHeaders = headers;
  } else {
    authorizedHeaders = "";
  }
  return axios
    .post(url, params, authorizedHeaders)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiPut(url, params) {
  return axios
    .put(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiPutNew(url, params, headers) {
  let authorizedHeaders = "";
  if (headers) {
    authorizedHeaders = headers;
  } else {
    authorizedHeaders = "";
  }
  return axios
    .put(url, params, authorizedHeaders)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export default class userAuth extends Component {
  render() {
    return <div>webapi</div>;
  }
}
