import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import OtpInput from 'react-otp-input';
import { NotificationContainer, NotificationManager } from "react-notifications/dist/react-notifications";
import { apiPost } from '../../../../Utilities/userAuth';
const ResetPassword = ({ showPasswordModel, onHidePasswordModel, PasswordEmail, PasswordOtp, setIsPasswordModelShow }) => {

    const [isloadingResetPass, setIsResetPass] = useState(false);

    const {
        register: registerResetPass,
        setError: registerResetPassError,
        reset: resetResetPass,
        setData: setDataOtp,
        watch,
        formState: { errors: errorsResetPass },
        handleSubmit: handleSubmitResetPass,
    } = useForm({
        shouldFocusError: true,
        keepValues: true,
    });
    const { REACT_APP_API_URL } = process.env;
    const password = useRef({});
    password.current = watch("password", "");

    const passWordReset = (data) => {

        setIsResetPass(true)
        let url = `${REACT_APP_API_URL}/users/resetPassword`;
        const params = {
            email: PasswordEmail,
            otp: PasswordOtp,
            password: data.confirm_password
        };
        apiPost(url, params)
            .then((response) => {
                setIsResetPass(false);
                if (response.data.code === 200) {
                    NotificationManager.success(response.data.message);
                    document.getElementById('enterOtp').style.display = 'none';
                    setIsPasswordModelShow(false)
                } else if (response.data.code === 404) {
                    registerResetPassError('confirm_password', { type: 'manual', message: response.data.message });
                } else {

                }
            })
            .catch((error) => { });
    }

    return (
        <div className={`modal fade ${showPasswordModel ? 'show' : ''}`} id="enterOtp" tabIndex="-1" aria-labelledby="enterOtpLabel" aria-hidden={!showPasswordModel} style={{ display: showPasswordModel ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" aria-label="Close" onClick={onHidePasswordModel}></button>
                    </div>
                    <div className="modal-body" style={{ paddingTop: '0' }}>
                        <>
                            <form onSubmit={handleSubmitResetPass(passWordReset)}>
                                <div className="fortydays-trails fallback-font" style={{ textAlign: 'center', lineHeight: '1', padding: '31px 45px', background: '#FA7153 no-repeat center bottom', backgroundSize: 'cover', backgroundImage: 'url(https://plansbid.ca/wp-content/uploads/2024/03/trail-bg.png)' }}>
                                    <div className="fallback-font" style={{ padding: '30px', borderRadius: '20px', lineHeight: '1', margin: '0', border: '1px solid #fff', backgroundColor: '#fff' }}>

                                        <div className="single-field-login mb-1" style={{ textAlign: 'left', fontSize: '15px' }}>
                                            <label htmlFor="password">
                                                Password <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="New password*"
                                                {...registerResetPass("password", {
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must have at least 8 characters",
                                                    },
                                                    required: "This field is required!",
                                                })}
                                            />
                                            <p className="text-danger fs-7 p-1">{errorsResetPass.password?.message}</p>
                                        </div>
                                        <div className="single-field-login mb-1" style={{ textAlign: 'left', fontSize: '15px' }}>
                                            <label htmlFor="c-password">
                                                Confirm Password <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Confirm password*"
                                                {...registerResetPass("confirm_password", {
                                                    validate: (val) => {
                                                        if (val !== password.current) {
                                                            return "The passwords do not match";
                                                        }
                                                    },
                                                })}
                                            />
                                            {errorsResetPass.confirm_password && <p className="text-danger fs-7 p-1">{errorsResetPass.confirm_password.message}</p>}
                                        </div>
                                        <button
                                            className={`${isloadingResetPass ? "disable-btn" : ""}`}
                                            disabled={isloadingResetPass}
                                            type='submit'
                                            style={{
                                                margin: '25px 0 0',
                                                display: 'inline-block',
                                                fontFamily: 'Inter, Arial, sans-serif',
                                                fontWeight: 700,
                                                padding: '19px 57px',
                                                fontSize: '20px',
                                                textDecoration: 'none',
                                                lineHeight: 1.2,
                                                color: '#fff',
                                                background: '#FA7153',
                                                borderRadius: '10px',
                                                maxWidth: '200px',
                                                width: '100%',
                                            }}
                                        >
                                            {isloadingResetPass ? (
                                                <span>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;
                                                </span>
                                            ) : (
                                                "Update"
                                            )}
                                            
                                        </button>
                                    </div>

                                    {/* /.message-box */}
                                </div>

                            </form>
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
