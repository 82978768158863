import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { apiPost, apiGet } from "../../../Utilities/userAuth";
import { getStorage } from "../../../Utilities/storage";
import { useForm } from "react-hook-form";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications/dist/react-notifications";
import Select from "react-select";
import "./Auth.scss";
import { access_token, container_id, countryMap, mapCoordinates, mapStyle } from "../../../Utilities/mapKeys";
import { apiResponse, handleError } from "../../../Utilities/Services/customHelper";
import pbzLogo from "../../../assests/new-images/logo.svg";

const Form = () => {
  const [next, setNext] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [newCreatedId, setNewCreatedId] = useState("");
  const { REACT_APP_API_URL } = process.env;
  const [addSelectedOption, setAddSelectedOption] = useState("");
  const [getStatesAndRegionLoading, setStatesAndRegionLoading] = useState(false);
  const [getStatesAndRegion, setGetStatesAndRegion] = useState();
  const [getAllTrades, setGetAllTrades] = useState(false);
  const [addSelectedTrade, setAddSelectedTrade] = useState([]);
  const [isTradeLoading, setIsTradeLoading] = useState(false);
  const [getAllTradesLoading, setGetAllTradesLoading] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  const [searchedLocation, setSearchedLocation] = useState("");
  const [mapInitialized, setMapInitialized] = useState(false);
  const [showToggle, setShowToggle] = useState(true);
  const [roleType, setRoleType] = useState("builder");
  console.log(searchedLocation);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    keepValues: true,
    defaultValues: {
      roles: "1",
    },
  });
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    let userInfo = getStorage("userInfo");
    if (userInfo) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    getStateAndRegions();
    getTradesMenu();
  }, []);

  const regionIndex = searchedLocation?.context?.findIndex((item) => item.id.startsWith("region"));
  const selectTheState = searchedLocation?.context && Array.isArray(searchedLocation.context) && regionIndex < searchedLocation.context.length && searchedLocation.context[regionIndex]?.text;

  const zipCodeIndex = searchedLocation?.context?.findIndex((item) => item.id.startsWith("postcode"));
  const selectZipCode = searchedLocation?.context && Array.isArray(searchedLocation.context) && zipCodeIndex < searchedLocation.context.length && searchedLocation.context[zipCodeIndex]?.text;
  console.log(selectZipCode, "-----");
  const cityIndex = searchedLocation?.context?.findIndex((item) => item.id.startsWith("place"));
  const selectCity = searchedLocation?.context && Array.isArray(searchedLocation.context) && cityIndex < searchedLocation.context.length && searchedLocation.context[cityIndex]?.text;

  useEffect(() => {
    if (selectTheState) {
      const selectedOption = selectTheState ? getStatesAndRegion?.find((option) => option.label === selectTheState) : null;
      setAddSelectedOption(selectedOption);
    }
  }, [selectTheState, getStatesAndRegion]);

  const createAccount = (formData) => {
    setIsLoading(true);

    if (newCreatedId === "") {
      let url = `${REACT_APP_API_URL}/users/register`;
      const params = {
        user_name: formData.user_name,
        user_email: formData.email,
        name: formData.name,
        phone: "+1" + formData.phone,
        password: formData.password,
        role_id: formData.roles,
        type: roleType,
        // user_state: formData.user_state,
        street_address: searchedLocation?.place_name,
        zip_code: selectZipCode || searchedLocation?.text,
        state: addSelectedOption.label,
        coordinates: JSON.stringify(searchedLocation),
        city: selectCity,
        state_id: addSelectedOption.id,
        area_of_trades: roleType === "builder" ? getAllTrades : addSelectedTrade,
      };
      apiPost(url, params)
        .then((response) => {
          setIsLoading(false);
          if (response.data.code === 200) {
            NotificationManager.success("Your account has been created successfully");
            setNewCreatedId(response.data.data.id);
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else if (response.data.code === 409) {
            NotificationManager.error("User has been already exists.");
          } else {
          }
        })
        .catch((error) => {
          NotificationManager.error("User has been already exists.");
        });
    }
  };

  let updateTrades = (data2) => {
    let url = `${REACT_APP_API_URL}/userTrades/update`;
    const formData = new FormData();
    formData.append("user_id", newCreatedId);
    formData.append("area_of_trades", JSON.stringify(addSelectedTrade));
    setIsTradeLoading(true);
    apiPost(url, formData)
      .then((response) => {
        if (response.data.code === 200) {
          NotificationManager.success("Successfully Created.");
          const timer = setTimeout(() => {
            navigate("/login/?signup=true");
          }, 1000);
        } else {
          apiResponse(response, navigate);
        }
        if (document.getElementById("closeTradeModel")) {
          document.getElementById("closeTradeModel").click();
        }
        setIsTradeLoading(false);
        setAddSelectedOption([]);
      })
      .catch((error) => {
        handleError();
      });
  };

  let getStateAndRegions = () => {
    setStatesAndRegionLoading(true);
    let url = `${REACT_APP_API_URL}/states/getAllStates`;

    apiGet(url)
      .then((response) => {
        if (response.data.code === 200) {
          let createNewOption = {};
          let valuseFromDb = response.data.data;
          let newArrState = [];
          let stateVal = "";
          for (let i = 0; i < valuseFromDb.length; i++) {
            stateVal = valuseFromDb[i].name;
            stateVal = stateVal.toLocaleLowerCase();
            stateVal = stateVal.replace(/ /g, "_");
            createNewOption = {
              value: stateVal,
              label: valuseFromDb[i].name,
              country_id: valuseFromDb[i].country_id,
              id: valuseFromDb[i].id,
            };
            newArrState.push(createNewOption);
          }
          setGetStatesAndRegion(newArrState);
        } else {
          apiResponse(response, navigate);
        }
        setStatesAndRegionLoading(false);
      })
      .catch((error) => {
        handleError();
      });
  };

  let getTradesMenu = () => {
    setGetAllTradesLoading(true);
    let url = `${REACT_APP_API_URL}/projectAreaTrades/getAll`;

    apiGet(url)
      .then((response) => {
        if (response.data.code === 200) {
          let createNewOption = {};
          let valuseFromDb = response.data.data;
          let newArrTrades = [];
          for (let i = 0; i < valuseFromDb.length; i++) {
            createNewOption = {
              value: valuseFromDb[i].id,
              label: valuseFromDb[i].name,
            };
            newArrTrades.push(createNewOption);
          }
          setGetAllTrades(newArrTrades);
        } else {
          apiResponse(response, navigate);
        }
        setGetAllTradesLoading(false);
        setIsTradeLoading(false);
      })
      .catch((error) => {
        handleError();
      });
  };

  useEffect(() => {
    if (next !== 3) {
      console.error("Mapbox not ready yet.");
      return;
    }

    mapboxgl.accessToken = access_token;
    if (!access_token) {
      console.error("Mapbox access token is missing.");
      return;
    }

    const containerElement = document.getElementById(container_id);
    if (!containerElement) {
      console.error("Container element not found.");
      return;
    }

    const map = new mapboxgl.Map({
      container: container_id,
      zoom: 3,
      style: mapStyle,
      center: mapCoordinates,
    });

    if (typeof MapboxGeocoder === "undefined") {
      console.error("MapboxGeocoder library is missing or not loaded.");
      return;
    }

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: countryMap,
      types: "postcode,address",
    });
    map.addControl(geocoder, "top-left");

    let mapbox_id = "";

    geocoder.on("result", (event) => {
      mapbox_id = event.result.id;
      setApiUrl(`https://api.mapbox.com/search/searchbox/v1/retrieve/${mapbox_id}?access_token=${access_token}`);
      setSearchedLocation(event.result);
      setValue("zip_code", selectZipCode || event.result.text);
    });

    return () => map.remove();
  }, [access_token, container_id, next, showToggle]);

  const handleChange = () => {
    setShowToggle(!showToggle);
  };
  const [isChecked, setIsChecked] = useState(false);
  function onSubmission(data) {
    setNext(3);
  }
  const isDisabled = searchedLocation?.text === "" || selectZipCode === undefined;

  // ================================== Map ===================================

  return (
    <section className="plansbirds-multiform page-bg" id="signup">
      <div className="container justify-content-center w-100" style={{ height: "100vh" }}>
        <div className="pbids-wrapper" id="plasbids-form" style={{ marginTop: "auto", marginBottom: "auto" }}>
          <NotificationContainer />
          <form onSubmit={handleSubmit(createAccount)}>
            {next === 0 ? (
              <div className="formbox signupbox mx-auto welcome-step">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="form-logo mb-5">
                      <img src={pbzLogo} alt="PlansBid" />
                    </div>
                    <h4 className="mt-2">Select option that describes you the best:</h4>
                  </div>
                  <div className="col-md-6">
                    <div className="forms-fields fieldbox describes-you">
                      <div className="single-dy" onClick={() => setRoleType("builder")}>
                        <input style={{ visibility: "hidden" }} className={`form-control ${roleType === "builder" ? "builder" : ""}`} type="radio" name="describes-you" id="general-builder" value={1} {...register("roles", { required: "This field is required!" })} />
                        <label htmlFor="general-builder" className="m-0 position-relative">
                          <h4>I am a General Contractor</h4>
                          <p className="mb-0">Looking to post projects and receive Sub Contractor and supplier bids</p>
                        </label>
                      </div>
                      <div className="single-dy" onClick={() => setRoleType("subcontractor")}>
                        <input style={{ visibility: "hidden" }} className={`form-control ${roleType === "subcontractor" ? "subcontractor" : ""}`} type="radio" name="describes-you" value={2} {...register("roles", { required: "This field is required!" })} id="sub-builder" />
                        <label htmlFor="sub-builder" className="m-0 position-relative">
                          <h4>I am a Sub Contractor</h4>
                          <p className="mb-0">Looking to post projects and receive Sub Contractor and supplier bids</p>
                        </label>
                      </div>
                      {/* <div className="single-dy" onClick={() => setRoleType("supplier")}>
                        <input style={{ visibility: "hidden" }} className={`form-control ${roleType === "supplier" ? "supplier" : ""}`} type="radio" name="describes-you" id="supplier-builder" value={3} {...register("roles", { required: "This field is required!" })} />
                        <label htmlFor="supplier-builder" className="m-0 position-relative">
                          <h4>I am a Supplier</h4>
                          <p className="mb-0">Looking to post projects and receive Sub Contactor and supplier bids</p>
                        </label>
                      </div> */}
                      <p className="text-danger fs-7 p-1">{errors.roles?.message}</p>
                      <button type="button" className="btn-custom" onClick={() => setNext(1)}>
                        Continue to Signup
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {next === 1 ? (
              <div className="formbox signup2 mx-auto">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-logo mb-4">
                      <img src={pbzLogo} alt="PlansBid" />
                    </div>
                    <h3>Create your Account</h3>
                  </div>
                  <div className="col-md-8 offset-md-1">
                    <div className="forms-fields fieldbox">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="single-field">
                            <label htmlFor="fullname">
                              Full Name <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="user_name" placeholder="Full Name" {...register("name", { required: "This field is required!" })} />
                            <p className="text-danger fs-7 p-1">{errors.name?.message}</p>
                          </div>
                          <div className="single-field">
                            <label htmlFor="username">
                              Username <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="user_name" placeholder="Username" {...register("user_name", { required: "This field is required!" })} />
                            <p className="text-danger fs-7 p-1">{errors.user_name?.message}</p>
                          </div>
                          <div className="single-field">
                            <label htmlFor="username">
                              Phone Number <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              {" "}
                              {/* Adding input-group for appending +61 */}
                              <div className="input-group-prepend">
                                <span className="input-group-text">+1</span>
                              </div>
                              <input className="form-control phnf" type="text" placeholder="123456789" {...register("phone", { required: "This field is required!" })} maxLength="10" />
                            </div>
                            <p className="text-danger fs-7 p-1">{errors.phone?.message}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-field">
                            <label htmlFor="email">
                              Email <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="email" placeholder="Email" {...register("email", { required: "This field is required!" })} />
                            <p className="text-danger fs-7 p-1">{errors.email?.message}</p>
                          </div>
                          <div className="single-field">
                            <label htmlFor="password">
                              Password <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              placeholder="New password*"
                              {...register("password", {
                                minLength: {
                                  value: 8,
                                  message: "Password must have at least 8 characters",
                                },
                                required: "This field is required!",
                              })}
                            />
                            <p className="text-danger fs-7 p-1">{errors.password?.message}</p>
                          </div>
                          <div className="single-field">
                            <label htmlFor="c-password">
                              Confirm Password <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              placeholder="Confirm password*"
                              {...register("confirm_password", {
                                required: "This field is required!",
                                validate: (val) => val === password.current || "The passwords do not match",
                              })}
                            />

                            {errors.confirm_password && <p className="text-danger fs-7 p-1">{errors.confirm_password.message}</p>}
                          </div>
                        </div>
                      </div>

                      <div className="single-field d-flex agreeterm">
                        <input type="checkbox" {...register("terms_condition", { required: "This field is required!" })} id="term-condition" />
                        <label htmlFor="term-condition" className="trmcd">
                          I agree to Plansbid{" "}
                          <a href="#" target="_blank">
                            Terms & Conditions
                          </a>
                        </label>
                      </div>
                      <p className="text-danger">{errors.terms_condition?.message}</p>
                      <div className="single-field btnsec d-flex justify-content-between">
                        <button type="button" className="btn-custom previous" onClick={() => setNext(0)}>
                          <i className="fas fa-arrow-left"></i> Back
                        </button>
                        <button type="button" onClick={handleSubmit(onSubmission)} className={`btn-custom`}>
                          Save & Continue
                        </button>
                      </div>
                      {/* <div className="single-field text-center haveaccount">
                        <p>Already part of our network?</p>
                        <Link to="/login">Sign In</Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {next === 2 ? (
              <div className="formbox mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-logo text-center mb-5">
                      <img src={pbzLogo} alt="PlansBid" />
                    </div>
                    <h3 className="text-center">Company Details</h3>
                  </div>
                  <div className="col-md-6">
                    <div className="forms-fields fieldbox">
                      <div className="single-field">
                        <input className="form-control" type="text" placeholder="Company Name*" required />
                      </div>
                      <div className="single-field">
                        <input className="form-control" type="text" placeholder="Address*" required />
                      </div>
                      <div className="single-field">
                        <input className="form-control" type="text" placeholder="City*" required />
                      </div>
                      <div className="single-field">
                        <input className="form-control" type="text" placeholder="State*" required />
                      </div>
                      <div className="single-field">
                        <input className="form-control" type="number" placeholder="Postal Code*" required maxLength={4} />
                      </div>

                      <div className="single-field">
                        <button
                          type="button"
                          className="btn-custom next"
                          onClick={() => {
                            setNext(3);
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <button type="button" className="previous" onClick={() => setNext(1)}>
                      <i className="fas fa-arrow-left"></i> Back
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {next === 3 ? (
              <div className="formbox regn mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-logo mb-4">
                      <img src={pbzLogo} alt="PlansBid" />
                    </div>
                    {/*form-logo */}
                    <h3>Help us personalize your project list and invitations:</h3>
                  </div>
                  <div className="col-md-6">
                    <div className="lets-set-succ">
                      <div className="succ-single">
                        <div className="ss-head-active d-flex">
                          <div className="ssh-left">
                            <span className="rgnum d-flex align-items-center justify-content-center">1</span>
                          </div>
                          {/*ssh-left */}
                          <div className="ssh-right">
                            <h4>Which regions do you cover?</h4>
                            <p>We will use this information to filter all future projects so that you receive only relevant bid invitations.</p>
                            <p>
                              Suggestions are based on your <b>postal code:</b>
                            </p>
                          </div>
                        </div>
                        {/*ss-head */}
                        <div className="forms-fields fieldbox sbfields">
                          <div className="single-field">
                            <div className="row mt-3">
                              <div className="col-md-12">
                                <div className="location-switcher d-flex align-items-center">
                                  <p>
                                    <strong>Note:</strong>&nbsp; Select Location with Map
                                  </p>
                                  <label className="switch mb-0">
                                    <input type="checkbox" checked={showToggle} onChange={handleChange} />
                                    <span className="slider round"></span>
                                  </label>
                                </div>

                                <p className="text-danger fs-7 p-1">{errors.zip_code?.message}</p>
                                {/* {selectZipCode === null || selectZipCode === undefined ? <div className="text-danger my-2">Please select your address correctly</div> : ""} */}
                              </div>
                              {/* Map */}
                              {showToggle ? (
                                <div className="px-3">
                                  <div id={container_id} style={{ width: "100%", height: "300px" }}>
                                    {/* The map will be rendered here */}
                                  </div>
                                  {searchedLocation === "" ? <p className="text-danger fs-7 p-1">Location is required</p> : ""}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="px-3 mb-2">
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register("zip_code", {
                                    required: "This field is required!",
                                  })}
                                  value={selectZipCode || searchedLocation?.text || ""}
                                  onChange={(e) => {
                                    setValue(e.target.value);
                                  }}
                                  placeholder="Postal Code *"
                                  disabled
                                />
                              </div>
                              {/* Searched Location: {JSON.stringify([searchedLocation])} */}

                              <div className="col-md-12">
                                {/* {getStatesAndRegionLoading ? "Loading.." : <Select placeholder="Choose State.." options={getStatesAndRegion} value={addSelectedOption} onChange={setAddSelectedOption} />} */}
                                {getStatesAndRegionLoading ? "Loading.." : <Select placeholder="Choose State.." options={getStatesAndRegion} value={addSelectedOption} onChange={setAddSelectedOption} isDisabled={true} />}
                                {/* <div className="opt">5 option(s) selected</div> */}
                                {/*opt */}
                                <p className="text-danger fs-7 p-1">{errors.state?.message}</p>
                              </div>
                            </div>
                            {/*row */}
                          </div>
                          {/*single-field */}
                          {showToggle ? (
                            <div className="single-field">
                              <button type="button" className={`btn-custom next ${searchedLocation === "" ? "btn-disabled" : ""}`} onClick={() => setNext(4)}>
                                Save & Continue
                              </button>
                            </div>
                          ) : (
                            <div className="single-field">
                              <button type="button" className="btn-custom btnnly next " onClick={() => setNext(4)}>
                                Save & Continue
                              </button>
                            </div>
                          )}

                          {/*single-field */}
                        </div>
                        {/*forms-fields */}
                      </div>
                      {/*succ-single */}
                    </div>
                    {/*lets-set-succ */}
                    <div className="left-diss d-flex align-items-center">
                      <div className="llbox">
                        <span className="d-flex align-items-center justify-content-center">2</span>
                      </div>{" "}
                      <p>What is your area of focus?</p>
                    </div>

                    {/*left-diss */}
                    {/* <div className="left-diss d-flex align-items-center">
                        <div className="llbox">
                          <span className="d-flex align-items-center justify-content-center">3</span>
                        </div>{" "}
                        <p>Contact information</p>
                      </div> */}
                    {/*left-diss */}
                    {/* <button type="button" className="previous" onClick={() => setNext(1)}>
                        <i className="fas fa-arrow-left"></i> Back
                      </button> */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {next === 4 ? (
              <div className="formbox atrade mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-logo mb-4">
                      <img src={pbzLogo} alt="PlansBid" />
                    </div>
                    {/*form-logo */}
                    <h3>Help us personalize your project list and invitations:</h3>
                  </div>
                  <div className="col-md-6">
                    <div className="left-diss left-diss-completed d-flex align-items-center">
                      <div className="llbox">
                        <span className="d-flex align-items-center justify-content-center">
                          <i className="fas fa-check"></i>
                        </span>
                      </div>{" "}
                      <p>Which regions do you cover?</p>
                    </div>
                    {/*left-diss */}
                    <div className="lets-set-succ">
                      <div className="succ-single">
                        <div className="ss-head-active d-flex">
                          <div className="ssh-left">
                            <span className="d-flex align-items-center justify-content-center">2</span>
                          </div>
                          {/*ssh-left */}
                          <div className="ssh-right">
                            <h4>What is your area of trades?</h4>
                            <p>Tell us about your work focus and we will filter your projects and save your time daily.</p>
                          </div>
                        </div>
                        {/*ss-head */}
                        <div className="forms-fields mt-5 iyn-fields">
                          {roleType !== "builder" ? (
                            <div className="single-field">
                              {/* <input className="form-control" type="text" {...register("area_of_focus", { required: "This field is required!" })} placeholder="Traders & Subtraders" /> */}
                              {getAllTradesLoading ? (
                                <span>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  &nbsp; Loading...
                                </span>
                              ) : (
                                <Select
                                  placeholder="Select Trades..."
                                  options={getAllTrades}
                                  value={addSelectedTrade}
                                  onChange={setAddSelectedTrade}
                                  //
                                  isMulti={true}
                                />
                              )}
                              {/* <div className="opt">5 option(s) selected</div> */}
                              {/*opt */}
                            </div>
                          ) : (
                            "All trades already set to your profile."
                          )}
                          <p className="text-danger fs-7 p-1">{errors.area_of_focus?.message}</p>
                          {/*single-field */}
                          {/* onClick={() => setNext(5)} */}
                          <div className="single-field d-flex justify-content-between mt-3">
                            {/* <button type="submit" className="btn-custom next" > */}

                            <button type="button" className="btn-custom mt-0 previous" onClick={() => setNext(3)}>
                              <i className="fas fa-arrow-left"></i> Back
                            </button>

                            <button type="submit" className="btn-custom">
                              {isLoading ? (
                                <span>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  &nbsp;
                                </span>
                              ) : (
                                "Create Account"
                              )}
                            </button>

                            {/* </button> */}
                          </div>
                          {/*single-field */}
                        </div>
                        {/*forms-fields */}
                      </div>
                      {/*succ-single */}
                    </div>
                    {/*lets-set-succ */}

                    {/* <div className="left-diss d-flex align-items-center">
                        <div className="llbox">
                          <span className="d-flex align-items-center justify-content-center">3</span>
                        </div>{" "}
                        <p>Contact information</p>
                      </div> */}
                    {/*left-diss */}
                    {/* <button type="button" className="previous" onClick={() => setNext(3)}>
                        <i className="fas fa-arrow-left"></i> Back
                      </button> */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {next === 5 ? (
              <div className="formbox mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-logo text-center mb-5">
                      <img src={pbzLogo} alt="PlansBid" />
                    </div>
                    {/*form-logo */}
                    <h3 className="text-center">Help us personalize your project list and invitations:</h3>
                  </div>
                  <div className="col-md-6">
                    <div className="left-diss left-diss-completed d-flex align-items-center">
                      <div className="llbox">
                        <span className="d-flex align-items-center justify-content-center">
                          <i className="fas fa-check"></i>
                        </span>
                      </div>{" "}
                      <p>What is your area of focus?</p>
                    </div>
                    {/*left-diss */}
                    <div className="left-diss left-diss-completed d-flex align-items-center">
                      <div className="llbox">
                        <span className="d-flex align-items-center justify-content-center">
                          <i className="fas fa-check"></i>
                        </span>
                      </div>{" "}
                      <p>What is your area of focus?</p>
                    </div>
                    {/*left-diss */}
                    <div className="lets-set-succ">
                      <div className="succ-single">
                        <div className="ss-head-active d-flex">
                          <div className="ssh-left">
                            <span className="d-flex align-items-center justify-content-center">3</span>
                          </div>
                          {/*ssh-left */}
                          {/* <div className="ssh-right">
                              <h4>Contact information</h4>
                              <p>We want to make sure that you are set for success and that other builders can reach you.</p>
                            </div> */}
                        </div>
                        {/*ss-head */}
                        <div className="forms-fields mt-0 iyn-fields">
                          <div className="single-field">
                            <input className="form-control" type="text" placeholder="Full Name*" {...register("name", { required: "This field is required!" })} />
                          </div>
                          {/*single-field */}

                          {/*single-field */}
                          <div className="single-field">
                            <div className="row">
                              <div className="">
                                <input className="form-control" type="tel" placeholder="Phone Number*" {...register("phone", { required: "This field is required!" })} />
                              </div>
                            </div>
                            {/*row */}
                          </div>
                          {/*single-field */}
                          <div className="single-field">
                            <button type="submit" className="btn-custom next">
                              {/* {isLoading ? (
                                  <span>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Saving...
                                  </span>
                                ) : ( */}
                              Save & Continue
                              {/* )} */}
                            </button>
                          </div>
                          {/*single-field */}
                        </div>
                      </div>
                      {/*succ-single */}
                    </div>
                    {/*lets-set-succ */}
                    <button type="button" className="previous" onClick={() => setNext(4)}>
                      <i className="fas fa-arrow-left"></i> Back
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </form>
          <div className="mt-3 pb-3 d-flex justify-content-center">
            <p>If you are already a member, please &nbsp;</p>{" "}
            <Link to="/login">
              <h6 className="text-primary text-decoration-underline">Login</h6>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
