import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { setStorage, getStorage, flushStorage } from "../../../Utilities/storage";
import { apiPost } from "../../../Utilities/userAuth";
import { Auth } from "../../../Context/authContext";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications/dist/react-notifications";
import "./Auth.scss";
import { handleError, apiResponse } from "../../../Utilities/Services/customHelper";
import { Link } from "react-router-dom";
import { AiFillWarning } from "react-icons/ai";
import pbzLogo from "../../../assests/new-images/logo.svg";

const Verify = () => {
  return (
    <section className="plansbirds-multiform page-bg " id="login">
      <div className="container justify-content-center w-100">
        <div className="pbids-wrapper" id="plasbids-form" style={{ marginTop: "auto", marginBottom: "auto" }}>
          <NotificationContainer />
          <form>
            <div className="formbox mx-auto welcome-step">
              <h3 className="text-center">Welcome to</h3>
              <div className="form-logo text-center mb-4">
                <img src={pbzLogo} alt="PlansBid" />
              </div>
              <div className="mt-5 mb-4 text-center">
                <p>Hey! Dear User Please click on verify button for complete the authentication steps and for account activation. Thank you!</p>
              </div>

              {/*forms-fields */}
              <button className="btn-custom" type="submit">
                Verify
              </button>
              <div className="sign-up d-flex mb-5 mt-3">
                <small>
                  If you don't have an account ?{" "}
                  <Link to="/sign-up" className="text-decoration-underline fw-bold">
                    Sign Up
                  </Link>
                </small>
              </div>
            </div>
          </form>
        </div>
        {/*pbids-wrapper */}
      </div>
      {/*container */}
    </section>
  );
};
export default Verify;
