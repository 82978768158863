import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { setStorage, getStorage, flushStorage } from "../../../Utilities/storage";
import { apiGet, apiPost } from "../../../Utilities/userAuth";
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications/dist/react-notifications";
import "./Auth.scss";
import { handleError } from "../../../Utilities/Services/customHelper";
import { Link } from "react-router-dom";
import { AiFillWarning } from "react-icons/ai";
import EnterOtp from "./Component/EnterOtp";
import ResetPassword from "./Component/ResetPassword";
import pbzLogo from "../../../assests/new-images/logo.svg";
import { Auth } from "../../../Context/authContext";

const AlertMessage = ({ alertMessage, setAlertMessage }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <div className="w-100 m-auto ">
      <div className="alert alert-danger text-center mx-0 my-5" role="alert">
        <AiFillWarning size={50} style={{ color: "red " }} />
        <p className="my-2"> &nbsp; {alertMessage}</p>
      </div>
    </div>
  );
};

const LoginForm = ({ onSubmit, register, errors, isLoading, alertScroll }) => (
  <form onSubmit={onSubmit}>
    <div className="formbox loginbox mx-auto welcome-step">
      <div className="row align-items-center">
        <div className="col-md-6 pdleft">
          <div className="form-logo">
            <img src={pbzLogo} alt="PlansBid" />
          </div>
          <h3>Login to your Account</h3>
        </div>
        <div className="col-md-6">
          <div className="fieldPart fieldbox">
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input className="form-control" name="email" type="text" {...register("email", { required: "This field is required!" })} placeholder="Enter your email address" />
              <p className="text-danger fs-7 p-1">{errors.email?.message}</p>
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input className="form-control" name="password" type="password" placeholder="Enter your password" {...register("password", { required: "This field is required!" })} />
              <p className="text-danger fs-7 p-1">{errors.password?.message}</p>
            </div>
            <div className="links">
              <input type="checkbox" id="remember" name="remember" />
              <label htmlFor="remember" className="rmpass">
                Remember me
              </label>
              <label className="forgot-password">
                <button type="button" className="forgot-passwordHover" data-bs-toggle="modal" data-bs-target="#fortPassword">
                  Forgot password?
                </button>
              </label>
            </div>
            <button className="btn-custom" type="submit" disabled={isLoading} onClick={alertScroll}>
              {isLoading ? (
                <span>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  &nbsp;
                </span>
              ) : (
                "Login"
              )}
            </button>
            <div className="sign-up">
              <small>
                Don't have an account?{" "}
                <Link to="/sign-up" className="">
                  Create an account
                </Link>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
);

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForgot, setIsLoadingForgot] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [optShowModal, setOptShowModal] = useState(false);
  const [isPasswordModelShow, setIsPasswordModel] = useState(false);
  const [resetEmailOpt, setResetEmailOpt] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: "", password: "" } });
  const {
    register: registerForgotPass,
    setError,
    reset: resetForgotPass,
    formState: { errors: errorsForgotPass },
    handleSubmit: handleSubmitForGotPass,
  } = useForm({
    shouldFocusError: true,
    keepValues: true,
  });

  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const userInfo = getStorage("userInfo");

  useEffect(() => {
    if (userInfo && userInfo.token) {
      window.location.href = "/projects";
    }
  }, [navigate]);

  const onSubmit = (data) => {
    setIsLoading(true);
    const url = `${REACT_APP_API_URL}/users/login`;
    const params = { user_email: data.email, password: data.password };
    apiPost(url, params)
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          NotificationManager.success(response.data.message);
          setStorage("userInfo", response.data.data);
          setStorage("token", response.data.data.token);

          setTimeout(() => {
            window.location.href = `${response.data.data.user_info.role_id === 6 ? "/takeoff-inquiry" : "/projects"}`;
          }, 250);
        } else {
          flushStorage();
          setAlertMessage(response.data.message);
        }
      })
      .catch(() => {
        flushStorage();
        setIsLoading(false);
        handleError();
      });
  };

  const alertScroll = () => {
    window.scrollTo({ top: -100, behavior: "smooth" });
  };

  const handleCloseModalOtp = () => {
    setOptShowModal(false);
  };

  const handleCloseModalReset = () => {
    setIsPasswordModel(false);
  };

  const forgotPasswordSub = (data) => {
    setIsLoadingForgot(true);
    const url = `${REACT_APP_API_URL}/users/forgotPassword`;
    const params = { email: data.forgotemail };
    apiPost(url, params)
      .then((response) => {
        setIsLoadingForgot(false);
        if (response.data.code === 200) {
          setResetEmailOpt(data.forgotemail);
          NotificationManager.success(response.data.message);
          document.getElementById("forgotPasswordClose").click();
          setTimeout(() => {
            setOptShowModal(true);
          }, 500);
        } else if (response.data.code === 409) {
          setError("forgotemail", { type: "manual", message: "The selected email is invalid." });
        }
      })
      .catch(() => {
        setIsLoadingForgot(false);
      });
  };

  return (
    <section className="plansbirds-multiform page-bg" id="login">
      <div className="container justify-content-center w-100 d-flex align-item-center">
        <div className="pbids-wrapper" id="plasbids-form" style={{ marginTop: "auto", marginBottom: "auto" }}>
          <NotificationContainer />
          {alertMessage && <AlertMessage alertMessage={alertMessage} setAlertMessage={setAlertMessage} />}
          <LoginForm onSubmit={handleSubmit(onSubmit)} register={register} errors={errors} isLoading={isLoading} alertScroll={alertScroll} />
        </div>
      </div>
      <div className="modal fade" id="fortPassword" tabIndex="-1" aria-labelledby="forgotPasswordLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="forgotPasswordLabel">
                Forget password?
              </h2>
              <button type="button" id="forgotPasswordClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-header" style={{ paddingTop: "0" }}>
              <p className="text-muted">Enter your registered login email below to get your unique link to reset the password.</p>
            </div>
            <div className="modal-body" style={{ paddingTop: "0" }}>
              <form onSubmit={handleSubmitForGotPass(forgotPasswordSub)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3 px-1">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input className="form-control" name="forgotemail" placeholder="Email" type="email" {...registerForgotPass("forgotemail", { required: "This field is required!" })} autoComplete="off" />
                      <p className="text-danger fs-7 p-1">{errorsForgotPass.forgotemail?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 p-0">
                  <button className={`btn-custom w-100 ${isLoadingForgot ? "disable-btn" : ""}`} type="submit" disabled={isLoadingForgot} style={{ backgroundColor: "var(--orange)" }}>
                    {isLoadingForgot ? (
                      <span>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp; Sending code...
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <EnterOtp showOtp={optShowModal} onHideOtp={handleCloseModalOtp} setOptShowModal={setOptShowModal} submitResetEmail={resetEmailOpt} />
      <ResetPassword showPasswordModel={isPasswordModelShow} onHidePasswordModel={handleCloseModalReset} setOptShowModal={setOptShowModal} />
    </section>
  );
};

export default Login;
