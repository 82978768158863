import React, { useEffect } from "react";
import { apiResponse, handleError } from "./Utilities/Services/customHelper";
import { apiGet, apiPost } from "./Utilities/userAuth";
import { getStorage } from "./Utilities/storage";
import "./assests/css/main.css";
import "./customStyle.scss";
import "react-notifications/lib/notifications.css";
// Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import { AuthProvider, NotificationsProvider } from "./Context/authContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignUp from "./Views/Users/Auth/SignUp";
import Verify from "./Views/Users/Auth/Verify";
import Login from "./Views/Users/Auth/Login";
import AllNotifications from "./Components/AllNotifications/AllNotifications";
import { userPermissions } from "./Utilities/Services/userPermissions";
const ProtectedLayout = React.lazy(() => import("./Layout/ProtectedLayout"));
const Dashboard = React.lazy(() => import("./Views/Users/Dashboard/Dashboard"));
const Settings = React.lazy(() => import("./Views/Users/Settings/Settings"));
const Directory = React.lazy(() => import("./Views/Users/Directory/Directory"));
const Suggestion = React.lazy(() => import("./Views/Users/Suggestion/Suggestion"));
const SearchDir = React.lazy(() => import("./Views/Users/Directory/Search"));
const BidSubmit = React.lazy(() => import("./Views/Users/Bidding/BidSubmit"));
const BidsMain = React.lazy(() => import("./Views/Users/Bidding/BidsMain"));
const BidDetail = React.lazy(() => import("./Views/Users/Bidding/BidDetail"));
const Projects = React.lazy(() => import("./Views/Users/Projects/index"));
const NewProjectDetail = React.lazy(() => import("./Views/Users/Projects/ProjectDetail/ProjectDetail"));
const PostProject = React.lazy(() => import("./Views/Users/Projects/PostProject"));
const ProjectMap = React.lazy(() => import("./Views/Users/Projects/Map/Map"));
const AddQuote = React.lazy(() => import("./Views/Users/Projects/AddQuote/AddQuote"));
const NewDirectory = React.lazy(() => import("./Views/Users/Directory/Directory"));
const DirectoryDetail = React.lazy(() => import("./Views/Users/Directory/DirectoryDetail/DirectoryDetail"));
const Pricing = React.lazy(() => import("./Views/Users/Pricing/Pricing"));
const Chat = React.lazy(() => import("./Views/Users/Chat/Chat"));
const Team = React.lazy(() => import("./Views/Users/Team/Team"));
const Inquiry = React.lazy(() => import("./Views/Users/Inquiry/Inquiry"));
const Billing = React.lazy(() => import("./Views/Users/Pricing/Billing/Billing"));
const ITBS = React.lazy(() => import("./Views/Users/ITBS/ITBS"));
const Role = React.lazy(() => import("./Views/Users/Role/Role"));

function App() {
  let userRoutV = userRoutesFun();
  let ProtectedRoutes = userRoutV.props.userRoutes;
  const userInfo = getStorage("userInfo");
  const permission = userPermissions;
  return (
    <>
      {userInfo ? (
        <AuthProvider>
          <NotificationsProvider>
            <BrowserRouter>
              <Routes>
                {/* Private Routes */}
                <Route element={<ProtectedLayout />}>{ProtectedRoutes && ProtectedRoutes.map((item, i) => <Route key={i} name={item.name} path={item.path} element={item.desktopModule} />)}</Route>
                {/* Public Routes */}
                <Route exact={true} name="verify" path="/verify" element={<Verify />}></Route>
                <Route exact={true} name="login" path="/login" element={<Login />}></Route>
                <Route exact={true} name="signup" path="/sign-up" element={<SignUp />}></Route>
                <Route exact={true} path="*" element={<Navigate to="/login" />}></Route>
              </Routes>
            </BrowserRouter>
          </NotificationsProvider>
        </AuthProvider>
      ) : (
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route exact={true} name="verify" path="/verify" element={<Verify />}></Route>
            <Route exact={true} name="login" path="/login" element={<Login />}></Route>
            <Route exact={true} name="signup" path="/sign-up" element={<SignUp />}></Route>
            <Route exact={true} path="*" element={<Navigate to="/login" />}></Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;

export function userRoutesFun() {
  const userInfo = getStorage("userInfo");
  var permission = userInfo?.user_info?.user_permissions;

  let userRoutes = [
    {
      exact: true,
      name: "billing",
      path: permission?.billing?.create === 1 ? "/billing-info" : "",
      desktopModule: <Billing />,
    },
    {
      exact: true,
      name: "allNotifications",
      path: "/allNotifications",
      desktopModule: <AllNotifications />,
    },
    {
      exact: true,
      name: "inquiry",
      path: "/takeoff-inquiry",
      desktopModule: <Inquiry />,
    },

    // New Directory Detail

    {
      exact: true,
      name: "directory",
      path: "/connection-user-profile/:id",
      desktopModule: <DirectoryDetail />,
    },

    // New Directory
    {
      exact: true,
      name: "directory",
      path: "/directory",
      desktopModule: <NewDirectory />,
    },
    // New Projects Quote
    {
      exact: true,
      name: "project-add-quote",
      path: permission?.projects_bid?.create === 1 ? "/add-quote/:id" : "",
      desktopModule: <AddQuote />,
    },
    {
      exact: true,
      name: "post-a-project",
      path: permission?.projects?.create === 1 ? "/post-a-project" : "",
      desktopModule: <PostProject />,
    },

    // New Projects Map
    {
      exact: true,
      name: "project-map",
      path: "/project-map",
      desktopModule: <ProjectMap />,
    },
    // ---------------
    // New Projects Detail
    {
      exact: true,
      name: "project-detail",
      path: "/project-detail/:id",
      desktopModule: <NewProjectDetail />,
    },
    // ---------------
    // New Projects
    {
      exact: true,
      name: "new-projects",
      path: "/projects",
      desktopModule: <Projects />,
    },
    // ---------------
    {
      exact: true,
      name: "gen-builder-setting",
      path: "/settings",
      desktopModule: <Settings />,
    },

    {
      exact: true,
      name: "team",
      path: "/team",
      desktopModule: <Team />,
    },

    {
      exact: true,
      name: "directory",
      path: "/directory",
      desktopModule: <Directory />,
    },
    {
      exact: true,
      name: "suggestion",
      path: "/suggestion",
      desktopModule: <Suggestion />,
    },
    {
      exact: true,
      name: "chat",
      path: "/chat",
      desktopModule: <Chat />,
    },
    {
      exact: true,
      name: "search-dir",
      path: "/users/search/",
      desktopModule: <SearchDir />,
    },

    {
      exact: true,
      name: "project-biding",
      path: "/projects/bid/:id",
      desktopModule: <BidSubmit />,
    },

    {
      exact: true,
      name: "payment",
      path: permission?.invoices?.create === 1 ? "/pricing" : "",
      desktopModule: <Pricing />,
    },
    {
      exact: true,
      name: "role",
      path: permission?.roles?.create === 1 && permission?.roles?.read === 1 && permission?.roles?.update === 1 && permission?.roles?.delete === 1 ? "/user/role" : "",
      desktopModule: <Role />,
    },

    {
      exact: true,
      name: "bids",
      path: "/project/bids",
      desktopModule: <BidsMain />,
    },
    {
      exact: true,
      name: "bid-detail",
      path: "/projects/bid-detail/:id",
      desktopModule: <BidDetail />,
    },
    {
      exact: true,
      name: "ITB-detail",
      path: "/projects/ITBs",
      desktopModule: <ITBS />,
    },

    {
      exact: true,
      name: "dashboard",
      path: "/dashboard",
      desktopModule: <Dashboard />,
    },
  ];

  return {
    props: {
      userRoutes,
    },
  };
}
