import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import OtpInput from 'react-otp-input';
import { apiPost } from '../../../../Utilities/userAuth';
import ResetPassword from './ResetPassword';
import { NotificationContainer, NotificationManager } from "react-notifications/dist/react-notifications";
const EnterOtp = ({ showOtp, onHideOtp, setOptShowModal, submitResetEmail }) => {
    const [otp, setOtp] = useState('');

    const [isloadingCheckFtp, setIsLoadingCheckftp] = useState(false);
    const [isPasswordModelShow, setIsPasswordModelShow] = useState(false);
    const [errorOpt, setErrorOpt] = useState('');

    const {
        register: registerOtpCheck,
        setError: registerOtpCheckError,
        reset: resetOtpCheck,
        setData: setDataOtp,
        formState: { errors: errorsOtpCheck },
        handleSubmit: handleSubmitOtpCheck,
    } = useForm({
        shouldFocusError: true,
        keepValues: true,
    });

    const handleCloseModalReset = () => {
        setIsPasswordModelShow(false)
    }
    const { REACT_APP_API_URL } = process.env;
    const optValidate = () => {
        setIsLoadingCheckftp(true)
        let url = `${REACT_APP_API_URL}/users/optVerify`;
        const params = {
            email: submitResetEmail,
            otp: otp,
        };
        apiPost(url, params)
            .then((response) => {
                setIsLoadingCheckftp(false);
                if (response.data.code === 200) {
                    document.getElementById('enterOtpdialoogi').style.display = 'none';
                    setTimeout(function () {
                        setIsPasswordModelShow(true)
                    }, 500)
                } else if (response.data.code === 404) {
                    setErrorOpt(response.data.message);
                } else {

                }
            })
            .catch((error) => {});

    }

    return (
        <div className={`modal fade ${showOtp ? 'show' : ''}`} id="enterOtp" tabIndex="-1" aria-labelledby="enterOtpLabel" aria-hidden={!showOtp} style={{ display: showOtp ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered" id="enterOtpdialoogi">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" aria-label="Close" onClick={onHideOtp}></button>
                    </div>
                    <div className="modal-body" style={{ paddingTop: '0' }}>
                        <>
                            <form onSubmit={handleSubmitOtpCheck(optValidate)}>
                                <div className="fortydays-trails fallback-font" style={{ textAlign: 'center', lineHeight: '1', padding: '31px 45px', background: '#FA7153 no-repeat center bottom', backgroundSize: 'cover', backgroundImage: 'url(https://plansbid.ca/wp-content/uploads/2024/03/trail-bg.png)' }}>
                                    <div className="fallback-font" style={{ padding: '30px', borderRadius: '20px', lineHeight: '1', margin: '0', border: '1px solid #fff', backgroundColor: '#fff' }}>
                                        <div className="logo-hand" style={{ lineHeight: '0.7', textAlign: 'center', margin: '0 0 8px', display: 'block' }}>
                                            <img style={{ maxWidth: '20%', width: 'auto !important', height: 'auto !important' }} src="https://plansbid.ca/wp-content/uploads/2024/03/message-question2.png" alt="" />
                                        </div>
                                        {/* /.logo-hand */}
                                        <div className="fallback-font" style={{ textAlign: 'center', color: '#323232', fontSize: '14px', fontWeight: '700', lineHeight: '1.6', fontFamily: "'Inter', Arial, sans-serif", margin: '0 0 6px' }}>
                                            Please check your email
                                        </div>
                                        <div className="fallback-font content-a" style={{ textAlign: 'center', color: '#373737', fontSize: '12px', fontWeight: '400', lineHeight: '1.4', fontFamily: "'Inter', Arial, sans-serif", margin: '0 0 px' }}>
                                            We've just sent a password reset email to your inbox.
                                        </div>
                                        <OtpInput
                                            value={otp}
                                            onChange={(e) => {
                                                setOtp(e);
                                                setErrorOpt('');
                                            }}
                                            numInputs={6}
                                            containerStyle={{
                                                alignItems: 'center',
                                                marginTop: '18px',
                                                justifyContent: 'space-between'
                                            }}
                                            inputStyle={{
                                                padding: '13px',
                                                borderRadius: '10px',
                                                borderColor: 'rgba(0, 0, 0, 0.2)',
                                                width: '3rem',
                                                height: '3rem',

                                            }}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        <p className="text-danger fs-7 p-1">{errorOpt}</p>
                                        <button
                                            className={`${isloadingCheckFtp ? "disable-btn" : ""}`}
                                            disabled={isloadingCheckFtp}
                                            type='submit'
                                            style={{
                                                margin: '25px 0 0',
                                                display: 'inline-block',
                                                fontFamily: 'Inter, Arial, sans-serif',
                                                fontWeight: 700,
                                                padding: '19px 57px',
                                                fontSize: '20px',
                                                textDecoration: 'none',
                                                lineHeight: 1.2,
                                                color: '#fff',
                                                background: '#FA7153',
                                                borderRadius: '10px',
                                                maxWidth: '200px',
                                                width: '100%',
                                            }}
                                        >
                                            {isloadingCheckFtp ? (
                                                <span>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;
                                                </span>
                                            ) : (
                                                "Submit"
                                            )}

                                        </button>
                                    </div>

                                    {/* /.message-box */}
                                </div>

                            </form>
                        </>
                    </div>
                </div>
            </div>
            <ResetPassword
                showPasswordModel={isPasswordModelShow}
                onHidePasswordModel={handleCloseModalReset}
                setOptShowModal={setOptShowModal}
                PasswordEmail={submitResetEmail}
                PasswordOtp={otp}
                setIsPasswordModelShow={setIsPasswordModelShow}
            />
        </div>
    );
};

export default EnterOtp;
