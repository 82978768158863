import React, { useEffect, useState } from "react";
import { useNotifications } from "../../Context/authContext.js";
import { format } from "date-fns";
import "./AllNotifications.scss";
import { apiPost } from "../../Utilities/userAuth.jsx";
import { useNavigate } from "react-router-dom";
import { getStorage } from "../../Utilities/storage.jsx";
import { NotificationContainer, NotificationManager } from "react-notifications/dist/react-notifications";
import { MdClose } from "react-icons/md";

const AllNotifications = () => {
  const { showNotification, getNotifications } = useNotifications();
  const [loadingIds, setLoadingIds] = useState([]);
  const navigate = useNavigate();
  const userInfo = getStorage("userInfo");
  const { REACT_APP_API_URL } = process.env;

  const handleUpdate = (id) => {
    setLoadingIds((prevLoadingIds) => [...prevLoadingIds, id]); // Add id to loadingIds

    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}/notification/update`;
      const params = {
        token: userInfo.token,
        id: id,
        is_active: 0,
      };
      apiPost(url, params)
        .then((response) => {
          if (response.data.code === 200) {
            getNotifications();
          } else if (parseInt(response.data.code) === 401) {
            navigate("/login");
          } else {
            let msg = "Something Wrong! Please try after some time.";
            NotificationManager.error(msg);
          }
        })
        .catch((error) => {
          let msg = "Something Wrong! Please try after some time.";
          NotificationManager.error(msg);
        })
        .finally(() => {
          setLoadingIds((prevLoadingIds) => prevLoadingIds.filter((loadingId) => loadingId !== id)); // Remove id from loadingIds
        });
    } else {
      navigate("/login");
    }
  };

  const isJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="userNotifications">
      {showNotification && showNotification.length > 0 ? (
        showNotification.map((item, index) => (
          <div className="notifi-bar" key={index}>
            <div className="notifi-text w-100 position-relative">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  {loadingIds.includes(item.id) ? (
                    <i className="ms-2">Reading..</i>
                  ) : (
                    <div className="ms-2">
                      <h5 style={{ fontSize: "14px" }}> {item.name}</h5>
                      <p className="text-muted" style={{ lineHeight: "10px", fontSize: "13px" }}>
                        {" "}
                        {!isJson(item.description) ? <div dangerouslySetInnerHTML={{ __html: item.description }} /> : "Notifications"}
                      </p>
                    </div>
                  )}
                </div>
                {loadingIds.includes(item.id) ? (
                  ""
                ) : (
                  <div className="">
                    <div style={{ lineHeight: "1px", textAlign: "end" }} className="d-flex justify-center gap-1 ">

                      <div className=" pt-2" style={{fontWeight:"bold"}}>
                      <small>{item.created_at && item.created_at ? `${format(new Date(item.created_at), "dd-MMMM")}` : "-"}</small>
                      <small style={{paddingLeft:"5px"}}>{item.created_at && item.created_at ? `${format(new Date(item.created_at), "HH:mm")}` : "-"}</small>
                      </div>
                      

                      <button className={` tooltip-read ${item.is_active === 0 ? "" : ""}`} onClick={() => handleUpdate(item.id)} disabled={loadingIds.includes(item.id)}>
                        <div className="tooltiptext-read">{loadingIds.includes(item.id) ? "Reading.." : "Mark as Read"}</div>
                        <MdClose className="icon-cross" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="p-3">No notification.</p>
      )}
    </div>
  );
};

export default AllNotifications;
